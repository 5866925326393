@import './constants.scss';

@mixin flex-style($direction, $align-items: '', $justify-content: '') {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin height-width($height: 0, $width: 0) {
  height: $height;
  width: $width;
}

.hours-add {
  @include flex-style(column);
  font-family: $montserrat;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  .active-subscription {
    margin-top: 30px;
    text-align: center;
    .modify-subscription {
      margin-top: 3rem;
      background-color: $light-grey;
    }
    .subs-info-text {
      font-size: 1rem;
      color: $gold;
    }
  }
  .modify-subscription {
    div {
      @include flex-style(row, '', space-between);
      padding: 1.8rem;
      i {
        font-size: 25px;
      }
    }
  }
  .modify-subscription:hover {
    cursor: pointer;
  }
  .monthly-subs,
  .a-la-carte {
    @include height-width(20%, 100%);
    margin-top: 50px;
    @include flex-style(column, '', center);
    button {
      background-color: $light-grey;
      padding: 1.8rem;
      width: 100%;
      border: none;
      margin-bottom: 1rem;
      @include flex-style(row);
      i {
        margin-left: auto;
        font-size: 25px;
      }
    }
    button:hover {
      cursor: pointer;
    }
    p {
      font-size: 1.1rem;
      font-family: $montserrat;
    }
    .helper-text {
      margin: 0 2rem 0 2rem;
      text-align: center;
      color: $medium-grey;
    }
  }
  .tab-switcher {
    @include flex-style(row, '', space-evenly);
    margin: 30px 1rem 1rem 1rem;
    font-size: 1.2rem;
    font-family: $adelle;
    letter-spacing: 2px;
    font-weight: 300;
    color: $black;
    p:hover {
      cursor: pointer;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.2rem;
    div {
      height: 150px;
      background-color: $off-grey;
      @include flex-style(column, center, center);
      padding: 1rem;
    }
    div:hover {
      cursor: pointer;
    }
    .active-subscription-selection {
      background-color: $off-white;
    }
  }
  .confirmation-details-text {
    margin-top: 2rem;
    text-align: center;
    p:nth-child(1) {
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.1rem;
    }
    p:nth-child(2) {
      margin: 0 1rem 0 1rem;
    }
  }
  .hours-form {
    @include flex-style(column);
    background-color: $light-grey;
    padding: 1.8rem;
    width: 100%;
    margin: 0;
    label {
      margin-bottom: 0.5rem;
      color: $medium-grey;
      font-size: 0.7rem;
    }
    div {
      @include flex-style(row, center);
      input {
        border: none;
        background-color: $light-grey;
        font-size: 1.2rem;
        color: black;
        padding: 0.1rem;
        width: 75%;
      }
      i {
        margin-left: auto;
      }
      i:hover {
        cursor: pointer;
      }
    }
  }
  .cancel-subscription {
    margin-top: 5rem;
    .cancel-subscription-text {
      padding: 1rem;
      text-align: center;
      margin-top: 1rem;
      background-color: $off-grey;
      p {
        margin: 1.5rem;
        color: $medium-grey;
      }
    }
  }

  .Form {
    margin-top: 5rem;
    .stripe-form-container {
      border: none;
      padding: 0.5rem;
      .card-info {
        @include flex-style(row, center);
        #expiry,
        #cvc {
          width: 100%;
        }
      }
      #cardNumber,
      #expiry,
      #cvc,
      #postal,
      #name {
        margin: 0.5rem 0 0.5rem 0;
        background-color: $off-grey;
        padding: 1.5rem 0.5rem 1.5rem 0.5rem;
        font-size: 0.8rem;
        font-family: $montserrat;
      }
      #postal,
      #name {
        outline: none;
        border: none;
        width: 100%;
      }
      #expiry {
        margin-right: 0.5rem;
      }
    }
    .card-error {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1rem;
      color: red;
    }
    .card-save {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      p {
        margin-left: 0.5rem;
      }
    }
  }

  .hours-confirm {
    margin-top: 100px;
    margin-bottom: auto;
    text-align: center;

    p:nth-child(1) {
      font-size: 1.5rem;
    }
    p:nth-child(2) {
      font-weight: bold;
      margin-bottom: 1rem;
    }
    p:nth-child(3) {
      padding: 1.5rem;
      color: $medium-grey;
    }
    .confirm-promo {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      margin-top: 0.5rem;
      i {
        color: $gold;
      }
      p {
        font-size: 1rem;
        font-weight: 400;
        margin-left: 0.5rem;
      }
      #promo {
        height: 60px;
        width: 50%;
        border: none;
        background-color: $off-grey;
        font-family: $futura-pt;
        font-size: 1rem;
        margin-right: 1rem;
        padding-left: 1rem;
        outline: none;
      }
      #promo-button {
        background-color: $black;
        border: 1px solid $green;
        color: $gold;
        font-family: $montserrat;
        padding: 0 0.5rem 0 0.5rem;
      }
    }
  }
  .margin-override {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .hours-success {
    text-align: center;
    margin-top: 100px;
    margin-bottom: auto;
    h1 {
      font-size: 90px;
      font-weight: 500;
      font-family: $california-palms;
    }
    p {
      color: $medium-grey;
      padding: 2rem;
    }
    #pattern-background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-repeat: no-repeat;
      object-fit: cover;
      z-index: -100;
    }
  }
}

// to find the corresponding component
#estimate-section {
  @include flex-style(column, '', center);
  div {
    @include height-width(auto, 100%);
    @include flex-style(row, '', center);
    position: relative;
    input {
      border: none;
      font-size: 20px;
      background-color: $off-grey;
      @include height-width(auto, 100%);
      outline: none;
      font-family: $futura-pt;
    }
  }
  #estimate-section-subs {
    justify-content: left;
    div {
      justify-content: left;
      align-items: center;
    }
  }
  p {
    font-size: 0.6rem;
    color: $medium-grey;
    margin-left: 30px;
    margin-right: auto;
    margin-top: 0.2rem;
  }
}

.saved-cc {
  background-color: #e3e1df;
  padding: 1.8rem;
  width: 100%;

  label {
    margin-bottom: 0.5rem;
    color: #746e6a;
    font-size: 0.7rem;
  }
}

p.payment-method {
  text-align: center;
  font-size: 1.2rem;
  font-family: 'adelle', serif;
  letter-spacing: 2px;
  font-weight: 300;
  color: #000000;
  margin: 1em 0 1em 0;
}

// .hours {
//   height: 100%;
//   @include flex-style(column);
//   font-family: $label-font;
//   #pattern_background {
//     position: absolute;
//     @include height-width(180px, 100%);
//     top: 0;
//     left: 0;
//     z-index: -1;
//     opacity: 0.5;
//     object-fit: cover;
//   }
//   a {
//     width: 300px;
//     border: none;
//     background: none;
//     padding: 1rem;
//     font-family: $label-font;
//     background-color: $black;
//     color: $gold;
//     text-transform: uppercase;
//     margin: 1rem auto 1rem auto;
//     text-decoration: none;
//     text-align: center;
//   }
//   .hours-info {
//     height: 150px;
//     @include flex-style(column, center, center);
//     #hours-text {
//       span {
//         font-size: 2rem;
//       }
//     }
//   }
//   hr {
//     background-color: $gold;
//     height: 3px;
//     border: none;
//   }
//   .hours-history {
//     @include flex-style(column);
//     margin: 1rem;
//     overflow-y: hidden;
//     .scrollable-history {
//       overflow-y: scroll;
//       margin-bottom: 2rem;
//       div {
//         width: 100%;
//         background-color: $light-grey;
//         margin-bottom: 0.5rem;
//         padding: 0.5rem;
//       }
//     }
// .mini-title {
//   text-transform: uppercase;
//   font-size: 1rem;
//   margin-bottom: 1rem;
// }
//   }
// }
