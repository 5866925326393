.App {
  height: 100vh;
  width: 100vw;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

@font-face {
  font-family: 'California Palms Script Thicker';
  src: local('California Palms Script Thicker'),
    url(./assets/fonts/CaliforniaPalms_Script_thicker.otf) format('opentype');
}

@font-face {
  font-family: 'Futura PT';
  src: url(./assets/fonts/FuturaPTBook.otf);
}

@font-face {
  font-family: 'Adelle Rg';
  src: url(./assets/fonts/Adelle_Reg.otf);
}

@font-face {
  font-family: 'Adelle Lt';
  src: url(./assets/fonts/Adelle_light.otf);
}
