$montserrat: 'Montserrat', sans-serif;
$commuter-sans: 'commuters-sans', sans-serif;
$adelle: 'adelle', serif;
$california-palms: 'California Palms Script Thicker', script;
$futura-pt: 'Futura PT';
$adelle-rg: 'Adelle Rg';
$adelle-lt: 'Adelle Lt';

$green: #385041;
$gold: #dfc9a6;
$black: #000000;
$white: #ffffff;
$off-white: #f6efe7;
$medium-grey: #746e6a;
$light-grey: #e3e1df;
$off-grey: #f8f8f8;
$header-grey: #efebe7;
