$theme-colors: (
  'primary': #DFC9A6,
  'secondary': #DDDDDD,
  'info': #2F4437,
  "danger": #ff4136
);
@import '~bootstrap/scss/bootstrap';



* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
