@import './constants.scss';

.profile {
  margin-top: 35px;
  #title {
    margin: 1rem auto 0 auto;
    position: relative;
    i {
      position: absolute;
      right: 25px;
    }
    i:hover {
      cursor: pointer;
    }
  }
}

.profile,
.profile-edit-form {
  text-align: center;
  #profile-img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: $off-grey;
    position: relative;
    margin: 1.5rem auto;
    i {
      color: gray;
      position: absolute;
      top: 40%;
      left: 44%;
    }
  }
  img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    margin: 1.5rem auto 1.5rem auto;
    border-radius: 50%;
  }
  .profile-input-blocks {
    width: 70%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto 0.5rem auto;
    padding: 1rem;
    background-color: $off-grey;
    p,
    label {
      font-size: 0.8rem;
      color: $medium-grey;
      margin-bottom: 5px;
      font-family: $futura-pt;
    }
    input {
      border: none;
      background-color: $off-grey;
      font-family: $futura-pt;
      width: 100%;
      height: 50px;
      padding: 0.3rem;
      font-size: 1.3rem;
      font-size: 1rem;
      outline: none;
      color: $black;
    }
  }
  .terms-agreement {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem 0 1.5rem;
    input {
      margin-right: 1rem;
    }
  }
}
