@import './constants.scss';

.dash {
  height: 100%;
  width: auto;
  display: flex;
  flex-direction: column;
  #dash-section {
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    .hours-summary {
      height: 150px;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 1.5rem;
      margin: 50px;
      border-bottom: 1px solid $off-white;
      background-image: url('../assets/Tula_Pattern_10x10_LightGoldTransBack.png');
      object-fit: cover;
      background-position: center;
      background-size: cover;
      text-align: center;
      box-shadow: -2px 2px 6px 0px rgba(116, 110, 106, 0.41);
      h4 {
        margin-bottom: 0.5rem;
        font-family: $adelle-lt;
        font-weight: 600;
        letter-spacing: 2px;
      }
      p {
        span {
          font-size: 1.5rem;
          margin-right: 0.2rem;
          color: $black;
        }
      }
      a {
        text-decoration: none;
        color: $green;
        font-family: $montserrat;
        font-weight: 500;
        font-size: 15px;
      }
    }
    .todo-hub {
      width: 70%;
      height: 50%;
      margin: 50px;
      padding: 0;
      box-shadow: -2px 2px 6px 0px rgba(116, 110, 106, 0.41);
      .new-todo-container {
        .first-todo {
          height: 70px;
          width: auto;
          background-color: $off-grey;
          margin-bottom: 0.5rem;
          padding: 1.5rem;
          a {
            text-decoration: none;
            color: $medium-grey;
            font-family: $futura-pt;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            i {
              margin-left: 20px;
            }
          }
        }
        .todos {
          overflow-y: auto;
        }
      }
    }
  }
}

.todo-hub {
  a {
    text-decoration: none;
    color: $medium-grey;

    i {
      margin-left: 20px;
    }
  }
  overflow: hidden;
  height: 100%;
  width: auto;
  .new-todo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    p {
      font-size: 30px;
      margin-bottom: 1rem;
      padding: 1.5rem 0 0 1.5rem;
      font-family: $adelle-rg;
      font-weight: lighter;
    }
    span a {
      margin-left: auto;
      padding: 1.5rem;
      text-decoration: none;
      color: $green;
      font-family: $montserrat;
      font-weight: 500;
      font-size: 15px;
    }
  }
  .new-todo-container {
    height: 100%;
    width: auto;
    overflow-y: scroll;

    .first-todo {
      height: 70px;
      width: auto;
      background-color: $off-grey;
      margin-bottom: 0.5rem;
      padding: 1.5rem;
      a {
        text-decoration: none;
        color: $medium-grey;
        font-family: $futura-pt;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        i {
          margin-left: 20px;
        }
      }
    }
    select,
    option {
      border: none;
      text-transform: uppercase;
      margin-left: 1.5rem;
      outline: none;
      background-color: white;
      font: unset;
    }
    select {
      margin-bottom: 1rem;
    }
    .todos {
      height: 100%;
      width: auto;
      div {
        height: 70px;
        width: auto;
        background-color: $off-grey;
        margin-bottom: 0.5rem;
        padding: 1.5rem;
        a {
          color: $black;
          margin: 0;
          font-family: $futura-pt;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          i {
            font-size: 0.75rem;
            margin-right: 5px;
          }
        }
        .todo-list-label-group {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .todo-list-label-group span:last-child {
          margin-left: auto;
        }
        span {
          margin-left: 0.5rem;
        }
        span.todo-notice-bubble {
          background-color: #e85252;
          padding: 2px 7px 2px 7px;
          font-size: 10px;
          border: 1px solid #e85252;
          border-radius: 50%;
          color: white;
        }
      }

      #helper-text {
        height: auto;
        width: 200px;
        margin: 2rem auto auto auto;
        text-align: center;
        color: $medium-grey;
      }
    }
  }
}

.todo-add {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  .todo-details {
    height: 100vh;
    width: auto;
    .text-area-wrapper {
      height: 300px;
      width: auto;
      background-color: $off-grey;
      padding: 2rem;
      margin-top: 2rem;
      textarea {
        height: 100%;
        width: 100%;
        background-color: $off-grey;
        border: none;
        font-size: 1.1rem;
        outline: none;
        font-family: $futura-pt;
      }
    }
    .DayPicker {
      display: block;
      width: 100%;
      max-width: 100%;
      margin-top: 2rem;
      .DayPicker-Month {
        width: 100%;
        margin: 0;
        .DayPicker-Caption {
          text-transform: uppercase;
          font-size: 0.8rem;
          text-align: center;
          margin-bottom: 2rem;
        }
        .DayPicker-Day {
          border: 1px solid $gold;
        }
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
          background-color: $black;
          border-radius: 0;
        }
      }
    }
  }
  .todo-success {
    margin-top: 5rem;
    #pattern-background {
      position: absolute;
      top: 0;
      height: 100vh;
      width: 100vw;
      background-size: cover;
      background-repeat: no-repeat;
      object-fit: cover;
      z-index: -100;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 200px;
      height: auto;
    }
    p {
      padding: 1rem;
      text-align: center;
      font-size: 2rem;
      z-index: 1;
    }
    h1 {
      font-family: $california-palms;
      font-weight: 400;
      font-size: 5rem;
    }
  }
  #title-input {
    margin: 1rem;
    margin-left: 1.5rem;
    height: 50px;
    width: 100%;
    background-color: $off-grey;
    font-size: 20px;
    border: none;
    outline: none;
    font-family: $futura-pt;
  }
  section {
    height: 80px;
    width: auto;
    background-color: $off-grey;
    padding: 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: $futura-pt;
    div {
      height: auto;
      width: 100%;
      color: $medium-grey;
      font-size: 20px;
      i {
        margin-right: 1.5rem;
        color: black;
      }
    }
  }
  section:hover {
    cursor: pointer;
  }
  .save-notice {
    font-size: 15px;
    text-align: center;
    color: $medium-grey;
  }
  .hours-input-label {
    position: absolute;
    left: 75px;
    font-size: 21px;
  }
  p.delete-link {
    font-size: 0.8rem;
    color: $medium-grey;
    text-decoration: underline;
    margin-top: 2rem;
  }
  a {
    text-decoration: none;
    font-size: 0.8rem;
    text-align: center;
  }
  .needs-approval,
  .insufficient-balance {
    background-color: rgb(255, 223, 223);
    font-size: 1rem;
    text-align: left;
    margin: 1rem;
    p {
      padding: 0.5rem;
      color: red;
    }
    p:nth-child(2),
    p:nth-child(3) {
      color: $medium-grey;
      padding: 0 0 0 0.5rem;
      text-align: left;
      font-size: 0.8rem;
    }
    button {
      width: auto;
    }
  }
}

.welcome-notification {
  position: absolute;
  bottom: 0;
  background-color: $medium-grey;
  padding: 1.3rem;
  margin: 1rem;
  border-radius: 10px;
  #title {
    color: $off-grey;
    text-transform: uppercase;
    font-size: 1rem;
  }
  #description {
    color: $off-grey;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.5;
  }
  p {
    color: $gold;
    font-size: 0.9rem;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    img {
      height: auto;
      width: 20px;
    }
  }
  a {
    color: $gold;
  }
}
