@import './constants.scss';

.home {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  #background-image {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.5;
    object-fit: cover;
    z-index: 1;
  }
  section {
    height: 500px;
    width: 30%;
    display: flex;
    background-color: #EFEBE7;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: -2px 2px 6px 0px rgba(116, 110, 106, 0.41);

    img {
      height: 180px;
      width: auto;
      z-index: 1;
      margin-bottom: 3rem;
    }
  }
}

.authenticator {
  height: 100%;
  text-align: center;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.815),
      rgba(255, 255, 255, 0.815)
    ),
    url('../assets/pattern__background.png');
  object-fit: cover;
  background-position: center;
  .breadcrumb {
    background-color: transparent;
  }
  .sign-in-auth,
  .signup-auth {
    box-shadow: -2px 2px 6px 0px rgba(116, 110, 106, 0.41);
    max-width: 50%;
    margin: 100px auto 0 auto;
    padding: 0.5rem;
    background-color: $white;
    .hand-font {
      font-family: $california-palms;
      font-size: 90px;
      color: $gold;
      margin-top: 10px;
    }
  }
  .signup-auth {
    margin: 50px auto 0 auto
  }
  #forgot-password-text {
    color: $medium-grey;
    text-decoration: underline;
    margin-top: 20px;
    font-family: $futura-pt;
  }
  #forgot-password-text:hover {
    cursor: pointer;
  }
  .forgot-password-auth {
    height: 250px;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    p {
      font-family: $futura-pt;
      color: $medium-grey;
      font-size: 21px;
      margin-bottom: 5px;
    }
    .forgot-password-container {
      width: 50%;
      display: flex;
      flex-direction: column;
      p {
        width: 70%;
        font-size: 0.8rem;
        margin: 40% auto 1rem auto;
        text-align: center;
        font-family: $montserrat;
        color: $medium-grey;
      }
      .input-blocks {
        height: 60px;
        margin-bottom: 0.5rem;
        background-color: $off-grey;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        input {
          height: 100%;
          width: 80%;
          border: none;
          background-color: $off-grey;
          font-family: $futura-pt;
          font-size: 1rem;
          margin-left: 1rem;
          outline: none;
        }
      }
    }
  }
}

.sign-in-input-container,
.signup-input-container,
.confirm-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  p {
    width: 70%;
    font-size: 0.8rem;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-family: $montserrat;
    color: $medium-grey;
  }
  .input-blocks {
    height: 60px;
    margin-bottom: 0.5rem;
    background-color: $off-grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    input,select {
      border: none;
      background-color: $off-grey;
      font-family: $futura-pt;
      width: 80%;
      height: 100%;
      font-size: 1rem;
      margin-left: 1rem;
      outline: none;
      color: $medium-grey;
    };
    }
}